<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">{{ project_name }} - {{ app_version }}</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>-->
          <li class="nav-item">
            <router-link class="nav-link" to="/users">Users</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/uploads">Uploads</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/marketplace">Marketplace</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/courses">Courses</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/donation">Donation</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact">Contact/Reply</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/rmp">RateMyProf</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/users/merge">MergeUsers</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/stats">Stats</router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" to="/files">Files</router-link>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="https://privateemail.com/appsuite" target="_blank">Ext:PrivateEmail</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="http://fbbot.trelosfoititis.gr/fbbot_runids.html" target="_blank">Ext:FBBot</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="http://admin.trelosfoititis.gr/tf-sr/index.html" target="_blank">Ext:TFSR</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="http://admin.trelosfoititis.gr/stats.html" target="_blank">OLD-Stats</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="http://admin.trelosfoititis.gr/uploads.html" target="_blank">OLD-Uploads</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="http://admin.trelosfoititis.gr/rmp.html" target="_blank">OLD-RateMyProf</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="http://admin.trelosfoititis.gr/useranalytics.html" target="_blank">OLD-Users</a>
          </li> -->

          <!-- <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
          </li>-->
        </ul>
        <!-- <router-link class="nav-item nav-link" to="/signin" v-if="!this.$store.state.user">Sign in</router-link>
        <router-link class="nav-item nav-link" to="/signup" v-if="!this.$store.state.user">Sign up</router-link>
        <router-link class="nav-item nav-link" to="#" v-if="this.$store.state.user" event @click.prevent="redirect_to_account">Account</router-link>
        <router-link class="nav-item nav-link" to="#" v-if="this.$store.state.user" event @click.prevent="sign_out">Logout</router-link>-->

        <form class="d-flex">
          <!-- <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" /> -->
          <button class="btn btn-danger" type="submit" @click.prevent="sign_out">Sign Out</button>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
  import { auth, signOut } from '@/services/firebase';

  // needed for collapse navbar
  import 'bootstrap';

  export default {
    name: 'navigation',
    props: ['project_name'],
    data() {
      return {
        app_version: null,
      };
    },
    created() {
      this.app_version = process.env.VUE_APP_VERSION;
    },
    methods: {
      sign_out: function () {
        var self = this;
        signOut(auth).then(() => {
          // cleanup store (user, roles etc so next user who signs does not use previous user's values)
          self.$store.commit('cleanup');
          self.$router.push('/signin');
        });
      },
      // redirect_to_account: function () {
      //   let account_type = this.$store.state.account_type;
      //   let current_path = this.$route.path;
      //   console.log(current_path, account_type);

      //   this.$router.push("/account");
      // },
      // bg_based_on_account_type: function(){
      //     if(this.$store.state.account_type == "expert"){
      //         return "bg-dark"
      //     }
      //     if(this.$store.state.account_type == "client"){
      //         return "bg-primary"
      //     }
      // }
    },
  };
</script>
