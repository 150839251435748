import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import Home from '@/views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER HOME:');
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/uploads',
    name: 'Uploads',
    component: () => import(/* webpackChunkName: "uploads" */ '@/views/Uploads.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Uploads:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/uploads/:uploadid',
    name: 'SingleUploadView',
    component: () => import(/* webpackChunkName: "single_upload_view" */ '@/views/SingleUploadView.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER SingleUploadView:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/marketplace',
    name: 'Marketplace',
    component: () => import(/* webpackChunkName: "marketplace" */ '@/views/Marketplace.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Marketplace:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/courses',
    name: 'Courses',
    component: () => import(/* webpackChunkName: "courses" */ '@/views/Courses.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Courses:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Users:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/donation',
    name: 'UserDonation',
    component: () => import(/* webpackChunkName: "user_donation" */ '@/views/UserDonation.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER UserDonation:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },
  {
    path: '/users/merge',
    name: 'UserMerge',
    component: () => import(/* webpackChunkName: "user_merge" */ '@/views/UserMerge.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER UserMerge:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/contact',
    name: 'UserContact',
    component: () => import(/* webpackChunkName: "user_contact" */ '@/views/UserContact.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER UserContact:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/rmp',
    name: 'RateMyProf',
    component: () => import(/* webpackChunkName: "rmp" */ '@/views/RateMyProf.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER RateMyProf:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/rmp/reviews',
    name: 'RateMyProfReviews',
    component: () => import(/* webpackChunkName: "rmp_reviews" */ '@/views/RateMyProfReviews.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER RateMyProfReviews:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/rmp/professors',
    name: 'RateMyProfProfessors',
    component: () => import(/* webpackChunkName: "rmp_professors" */ '@/views/RateMyProfProfessors.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER RateMyProfProfessors:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/files',
    name: 'Files',
    component: () => import(/* webpackChunkName: "files" */ '@/views/Files.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER StatsHome:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/stats',
    name: 'StatsHome',
    component: () => import(/* webpackChunkName: "stats_home" */ '@/views/StatsHome.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER StatsHome:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/stats/universities',
    name: 'StatsUniversities',
    component: () => import(/* webpackChunkName: "stats_universities" */ '@/views/StatsUniversities.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER StatsUniversities:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/stats/departments',
    name: 'StatsDepartments',
    component: () => import(/* webpackChunkName: "stats_departments" */ '@/views/StatsDepartments.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER StatsDepartments:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/stats/users',
    name: 'StatsUsers',
    component: () => import(/* webpackChunkName: "stats_users" */ '@/views/StatsUsers.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER StatsUsers:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },
  {
    path: '/stats/downloads',
    name: 'StatsDownloads',
    component: () => import(/* webpackChunkName: "stats_downloads" */ '@/views/StatsDownloads.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER StatsDownloads:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/stats/entities',
    name: 'StatsEntities',
    component: () => import(/* webpackChunkName: "stats_entities" */ '@/views/StatsEntities.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER StatsEntities:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },
  {
    path: '/stats/marketplace',
    name: 'StatsMarketplace',
    component: () => import(/* webpackChunkName: "stats_marketplace" */ '@/views/StatsMarketplace.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER StatsMarketplace:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },
  {
    path: '/signin',
    name: 'UserSignIn',
    component: () => import(/* webpackChunkName: "signin" */ '@/views/UserSignIn.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER SignIN:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next('/');
      } else {
        next();
      }
    },
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "page_not_found" */ '@/views/PageNotFound.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER PageNotFound:', store.state.user);
      next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from) => {
//   console.log(to, from);
//   // ...
//   // explicitly return false to cancel the navigation
//   // return false;
// });

export default router;
