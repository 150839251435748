<template>
  <div class="container-fluid mt-5 pt-3 h-100 overflow-auto">
    <div class="row">
      <div class="col-xl-6 offset-xl-3"><ActionItems @actionItemSelected="action_item_selected" /></div>
      <!-- <div class="col-md-6">
        <h4>Resources:</h4>
        <br />
        <div v-if="selected_action_item?.data.event == 'marketplace_seller_payment_request'" :class="highlighted_class">
          <a href="https://wise.com/account-selector?select=34220298&redirectUrl=%2Fhome" target="_blank">Wise TF</a>
        </div>
        <div v-if="selected_action_item?.data.event == 'user_contact_email'" :class="highlighted_class">
          <a :href="`/contact?user=${selected_action_item?.data.useremail}`" target="_blank">Contact user</a>
          {{ selected_action_item?.data.useremail }}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  import { appAlertBuilder } from '@/helpers/app.js';
  import ActionItems from '@/components/ActionItems';

  const MAIN_ENDPOINT = process.env.VUE_APP_MAIN_ENDPOINT;
  const DEBUG = process.env.VUE_APP_DEBUG_MODE == 'true';

  export default {
    components: {
      ActionItems,
    },
    data() {
      return {
        selected_action_item: null,
        highlighted_class: '',
      };
    },
    methods: {
      init: function () {
        var self = this;
        console.log('Initiallized.');
      },

      action_item_selected: function (ai) {
        var self = this;
        console.log(ai);
        self.selected_action_item = ai;

        self.highlighted_class = 'item-highlighted';
        setTimeout(function () {
          self.highlighted_class = '';
        }, 500);
      },
    },
    created() {
      var self = this;
      self.init();
    },
  };
</script>

<style scoped>
  .item-highlighted {
    border-style: solid;
    border-color: red;
  }
</style>
