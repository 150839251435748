<template>
  <div>
    <div v-if="loading_in_progress" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <span v-html="loading_status"></span>

    <!-- <strong>Top {{ preview_top }} most recent events from total {{ action_items_list.length }}</strong> -->
    &nbsp;

    <button
      class="btn btn-secondary btn-block mb-3"
      type="button"
      :disabled="filter_in_progress"
      v-if="show_only_wip_items"
      @click.prevent="fetch_action_items(false)">
      <div v-if="filter_in_progress" class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      Show All
    </button>

    <button
      class="btn btn-secondary btn-block mb-3"
      type="button"
      :disabled="filter_in_progress"
      v-if="!show_only_wip_items"
      @click.prevent="fetch_action_items(true)">
      <div v-if="filter_in_progress" class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      Show WIP only
    </button>

    <br />
    <br />
    <div
      v-for="(ai, index) in action_items_list.slice(0, preview_top)"
      :key="index"
      :class="get_admin_status_class(ai)"
      @click="select_item(ai)">
      [{{ index + 1 }}/{{ action_items_list.length }}]
      <!-- {{ ai }} -->
      <strong>{{ ai.data.date }}</strong>
      -
      <strong>{{ ai.data.time }}</strong>
      -
      <span v-html="get_event_html(ai)"></span>
      -
      <span v-html="get_url_html(ai)"></span>
      &nbsp;
      <button
        class="btn btn-secondary btn-sm"
        type="button"
        v-if="ai?.admin_status == null || ai?.admin_status == 'PENDING'"
        :disabled="update_in_progress"
        @click.prevent="update_action_item(ai.itemid, 'MARK_AS_COMPLETED')">
        <div v-if="update_in_progress && ai.itemid == wip_item" class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        Complete-it
      </button>
      <button
        class="btn btn-secondary btn-sm"
        type="button"
        v-if="ai?.admin_status == 'COMPLETED'"
        :disabled="update_in_progress"
        @click.prevent="update_action_item(ai.itemid, 'MARK_AS_PENDING')">
        <div v-if="update_in_progress && ai.itemid == wip_item" class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        WIP-it
      </button>
      <span v-html="update_status"></span>
      <br />
      <UserEmail :email="extract_user_email(ai)" />
      <br />
      <span v-html="get_event_contents(ai)"></span>
      <hr />
    </div>
    <!-- <a href="/action-items">View all events..</a> -->
    <br />
    <br />
  </div>
</template>

<script>
  import UserEmail from '@/components/UserEmail.vue';
  import { appAlertBuilder } from '@/helpers/app.js';
  var ADMIN_ENDPOINT = process.env.VUE_APP_ADMIN_ENDPOINT;

  export default {
    components: {
      UserEmail,
    },
    data() {
      return {
        loading_in_progress: null,
        loading_status: null,

        action_items_list: [],
        preview_top: 200,

        show_only_wip_items: true,
        filter_in_progress: false,

        update_in_progress: null,
        update_status: null,
        wip_item: null,
        selected_item: null,
      };
    },
    methods: {
      filter_wip_only: function () {
        var self = this;
        self.filter_in_progress = true;

        let filtered_items = [];
        for (let index = 0; index < self.action_items_list.length; index++) {
          const element = self.action_items_list[index];
          if (element?.admin_status != 'COMPLETED') {
            filtered_items.push(element);
          }
        }

        self.filter_in_progress = false;
        self.action_items_list = filtered_items;
      },

      select_item: function (ai) {
        var self = this;
        self.selected_item = ai;
        console.log('selected item: ', self.selected_item);
        // turn off fow now, seems redundant since we can do what we want
        // directly in the action items
        // self.$emit('actionItemSelected', self.selected_item);
      },

      get_admin_status_class: function (ai) {
        if (ai?.admin_status == 'COMPLETED') {
          return 'item-completed';
        } else if (ai?.admin_status == 'PENDING') {
          return 'item-pending';
        } else {
          return '';
        }
      },

      extract_user_email: function (ai) {
        if (ai.data.event == 'marketplace_seller_payment_requested') {
          return ai.data?.username;
        } else if (ai.data.event == 'user_contact_email') {
          return `${encodeURIComponent(ai.data?.useremail)}`;
        } else if (ai.data.event == 'paypal_payment_email_not_found') {
          return ai.data?.paypal_email;
        } else if (ai.data.event == 'paypal_self_service_transaction_confirmation_attempt') {
          return ai.data?.user_email;
        } else {
          return 'unknown';
        }
      },

      get_event_html: function (ai) {
        if (ai.data.event == 'marketplace_seller_payment_requested') {
          return '<span class="badge rounded-pill bg-danger">' + ai.data.event + '</span>';
        } else if (ai.data.event == 'user_contact_email') {
          return '<span class="badge rounded-pill bg-primary">' + ai.data.event + '</span>';
          //   } else if (ai.data.event == 'user_deleted') {
          //     return '<span class="badge rounded-pill bg-dark">' + ai.data.event + '</span>';
        } else if (ai.data.event == 'paypal_payment_email_not_found') {
          return '<span class="badge rounded-pill bg-warning text-dark">' + ai.data.event + '</span>';
        } else if (ai.data.event == 'paypal_self_service_transaction_confirmation_attempt') {
          return '<span class="badge rounded-pill bg-warning text-dark">' + ai.data.event + '</span>';
        } else {
          return ai?.data?.event;
        }
      },

      get_url_html: function (ai) {
        if (ai.data.event == 'marketplace_seller_payment_requested') {
          return `<a href="/users?user=${ai.data?.userid}" target="_blank">UserProfile</a>
        -
        <a href="/contact?user=${ai.data?.username}" target="_blank">Contact</a>
        -
        <a href="https://wise.com/account-selector?select=34220298&redirectUrl=%2Fhome" target="_blank">Wise TF</a>`;
        } else if (ai.data.event == 'user_contact_email') {
          return `<a href="/users?user=${encodeURIComponent(ai.data?.useremail)}" target="_blank">UserProfile</a>
        -
        <a href="/contact?user=${encodeURIComponent(ai.data?.useremail)}" target="_blank">Contact</a>`;
        } else if (ai.data.event == 'rmp_new_prof') {
          return `<a href="/rmp/professors" target="_blank">RateMyProf</a>`;
        } else if (ai.data.event == 'rmp_new_review') {
          return `<a href="/rmp/reviews" target="_blank">RateMyProf</a>`;
        } else if (ai.data.event == 'user_deleted') {
          return `<a href="/users?user=${ai.data?.userid}" target="_blank">UserProfile</a>`;
        } else if (ai.data.event == 'paypal_payment_email_not_found') {
          return `<a href="/users?user=${ai.data?.paypal_email}" target="_blank">UserProfile</a> - <a href="https://www.paypal.com/activity/payment/${ai.data?.paypal_payment_id}" target="_blank">ViewAtPaypal</a>`;
        } else if (ai.data.event == 'paypal_self_service_transaction_confirmation_attempt') {
          return `<a href="/users?user=${ai.data?.user_email}" target="_blank">UserProfile</a> - <a href="https://www.paypal.com/activity/payment/${ai.data?.paypal_payment_id}" target="_blank">ViewAtPaypal</a>`;
        } else {
          return '';
        }
      },

      get_event_contents: function (ai) {
        if (ai.data.event == 'marketplace_seller_payment_requested') {
          return `UserId: ${ai.data?.userid}<br>UserEmail: ${ai.data?.username}<br>CurrentBalance: ${parseFloat(
            ai.data?.current_balance_in_cents / 100
          ).toFixed(2)} &euro;<br>`;
        } else if (ai.data.event == 'user_contact_email') {
          return `${ai.data?.usermessage}<br>`;
        } else if (ai.data.event == 'user_referral') {
          return `${ai.data?.refcode}<br>
        <a href="/users?user=${ai.data?.referral_userid}" target="_blank">UserInvited</a><br>
        <a href="/users?user=${ai.data?.invitation_userid}" target="_blank">UserWhoInvites</a><br>`;
        } else if (ai.data.event == 'paypal_payment_email_not_found') {
          return `PaypalEmail: ${ai.data?.paypal_email}<br>PaypalAmount: <strong>${ai.data?.paypal_amount} &euro;</strong><br>PaypalMemo: [${ai.data?.paypal_memo}]<br>PaypalPaymentId: [<strong>${ai.data?.paypal_payment_id}</strong>] <a href="https://trelosfoititis.gr/paypal-self-service?paypal_tx_id=${ai.data?.paypal_payment_id}" target="_blank">Paypal-Self-Service</a>`;
        } else if (ai.data.event == 'paypal_self_service_transaction_confirmation_attempt') {
          return `PaypalEmail: ${ai.data?.user_email}<br>PaypalPaymentId: [<strong>${
            ai.data?.paypal_tx_id
          }</strong>] <a href="https://trelosfoititis.gr/paypal-self-service?paypal_tx_id=${
            ai.data?.paypal_tx_id
          }" target="_blank">Paypal-Self-Service</a><br>Status: ${ai.data?.message} ${
            ai.data?.message == 'PAYPAL_TRANSACTION_CONFIRMED'
              ? `<span class="badge rounded-pill bg-success">Success</span>`
              : ``
          }<br>`;
        } else {
          return JSON.stringify(ai.data);
        }
      },

      fetch_action_items: async function (wip_only) {
        var self = this;
        self.loading_in_progress = true;
        self.loading_status = null;

        await self.$store.dispatch('getValidToken');
        return fetch(`${ADMIN_ENDPOINT}/action-items`, {
          headers: {
            'Authorization': self.$store.state.token,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log(response);
            let success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.loading_in_progress = false;

              // PROCESS
              self.action_items_list = data;
              self.show_only_wip_items = wip_only;
              if (wip_only) {
                self.filter_wip_only();
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            self.loading_in_progress = false;
            self.loading_status = appAlertBuilder(error, 'danger');
          });
      },

      update_action_item: async function (item_id, item_new_status) {
        var self = this;

        // this is global
        self.wip_item = item_id;

        self.update_in_progress = true;
        self.update_status = null;

        await self.$store.dispatch('getValidToken');
        return fetch(`${ADMIN_ENDPOINT}/action-items`, {
          method: 'PUT',
          headers: {
            'Authorization': self.$store.state.token,
          },
          body: JSON.stringify({
            itemid: item_id,
            action: item_new_status,
          }),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log(response);
            let success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.update_in_progress = false;
              // self.update_status = 'Request Submitted!';
            }

            // PROCESS
            self.action_items_list = data;
            if (self.show_only_wip_items) {
              self.filter_wip_only();
            }
          })
          .catch(function (error) {
            console.log(error);
            self.update_in_progress = false;
            self.update_status = appAlertBuilder(error, 'danger');
          });
      },
      init: async function () {
        var self = this;
        await self.fetch_action_items(true);
      },
    },
    created() {
      var self = this;
      self.init();
    },
  };
</script>

<style scoped>
  .item-completed {
    background-color: #94c296;
  }
  .item-selected {
    border-style: solid;
  }
  /* .item-pending {
  background-color: #ff9248;
} */
</style>
