<template>
  <span>
    Email:
    <strong>{{ user_email }}</strong>
    <button class="copybtn m-1" :data-clipboard-text="user_email">Copy</button>
  </span>
</template>

<script>
  import { appAlertBuilder } from '@/helpers/app.js';
  import ClipboardJS from 'clipboard';

  const MAIN_ENDPOINT = process.env.VUE_APP_MAIN_ENDPOINT;
  const DEBUG = process.env.VUE_APP_DEBUG_MODE == 'true';

  export default {
    props: ['email'],
    data() {
      return {
        user_email: null,
      };
    },
    methods: {
      init: function () {
        var self = this;
        self.user_email = self.$props.email ? decodeURIComponent(self.$props.email) : '';
        console.log('Initiallized, with email:', self.user_email);
      },
    },
    created() {
      var self = this;
      self.init();
    },
    mounted() {
      var self = this;
      new ClipboardJS('.copybtn');
    },
  };
</script>

<style></style>
