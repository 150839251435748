<template>
  <div id="app" class="d-flex flex-column vh-100">
    <Navigation
      :project_name="project_name"
      v-if="this.$store.state.user && this.$store.state.user_roles.includes('admin')" />
    <!-- <router-view class="container-fluid" :project_name="project_name" /> -->
    <div class="flex-grow-1 d-flex overflow-hidden">
      <router-view :project_name="project_name" />
    </div>
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation.vue';

  export default {
    components: {
      Navigation,
    },
    data() {
      return {
        project_name: 'AdminTrelosFoititis',
      };
    },
    created() {
      let app_name = process.env.VUE_APP_NAME;
      let app_version = process.env.VUE_APP_VERSION;
      let app_git_commit = process.env.VUE_APP_GIT_COMMIT;
      console.log(app_name + '@' + app_version + '@' + app_git_commit);
    },
    methods: {},
  };
</script>

<style>
  body,
  html {
    height: 100%;
    overflow: hidden;
  }
  #app {
    height: 100%;
  }
</style>
